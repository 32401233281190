import { customFetchWithRetry } from "./shared";

export const getRejectionMapping = async (grainId: string): Promise<Record<string, string>> => {
  const url = `/grain/${grainId}`;

  try {
    const response = await customFetchWithRetry(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
      },
    });

    const grainType = await response.json();

    if (grainType && grainType.rejectionMapping) {
      return grainType.rejectionMapping;
    } else {
      throw new Error(`Rejection mapping not found for grainId ${grainId}`);
    }
  } catch (error) {
    console.error("Error fetching rejection mapping:", (error as Error).message);
    throw new Error(`Failed to fetch rejection mapping: ${(error as Error).message}`);
  }
};
