import { customFetchWithRetry } from './shared';
import FetchError from './errors';
import {
  CreateTrainingJobRequest,
  TrainingJobInfo,
} from "../models/Training";

export const createTrainingJob = async (request: CreateTrainingJobRequest): Promise<string> => {
  try {
    const response = await customFetchWithRetry(`/ml/training`, {
      method: 'POST',
      body: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new FetchError(`Error creating training job. ${await response.text()}`, response.status);
    }

    const responseData = await response.json();
    return responseData.trainingJobID;
  } catch (e) {
    console.log('Error creating training job', e);
    throw e instanceof FetchError ? e : new FetchError((e as Error).message, 500);
  }
};

export const fetchTrainingJobDetails = async (trainingJobId: string): Promise<TrainingJobInfo> => {
  try {
    const response = await customFetchWithRetry(`/ml/training/${trainingJobId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new FetchError(`Error fetching training job details. ${await response.text()}`, response.status);
    }

    return response.json();
  } catch (e) {
    console.log('Error fetching training job details', e);
    throw e instanceof FetchError ? e : new FetchError((e as Error).message, 500);
  }
};