
import { customFetchWithRetry } from './shared';
import FetchError from './errors';
import { 
    CreateDatasetRequest,
    IImageWithLabel,
    DatasetInfo,
    ICompleteLabelingRequest,
    UpdateImageLabelsRequest
  } from "../models/UploadImages";
  
export const createDataset = async (request: CreateDatasetRequest): Promise<string> => {
    try {
      const response = await customFetchWithRetry(`/ml/dataset`, {
        method: 'POST',
        body: JSON.stringify(request),
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new FetchError(`Error creating dataset. ${await response.text()}`, response.status);
      }
  
      const responseText = await response.text();
      const datasetID = responseText.split(' ').pop();
      
      if (!datasetID) {
        throw new Error('Failed to get dataset ID from response');
      }
  
      return datasetID;
  
    } catch (e) {
      console.log('Error creating dataset', e);
      throw e instanceof FetchError ? e : new FetchError((e as Error).message, 500);
    }
  };
  
  export const fetchDatasetInfo = async (): Promise<DatasetInfo[][]> => {
    try {
      const response = await customFetchWithRetry(`/ml/dataset`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new FetchError(`Error fetching dataset info. ${await response.text()}`, response.status);
      }
  
      const data = await response.json();
      const datasetInfo = data.map((dataset: {
        grainId: any;
        username: any;
        updatedAt: any; datasetID: string; status: string 
}) => ({
        datasetID: dataset.datasetID,
        status: dataset.status,
        grainId: dataset.grainId,
        username: dataset.username,
        createdAt: dataset.updatedAt
      }));
      return datasetInfo;
    } catch (e) {
      console.log('Error fetching dataset info', e);
      throw e instanceof FetchError ? e : new FetchError((e as Error).message, 500);
    }
  };
  
  
  export const fetchDatasetDetails = async (datasetId: string): Promise<ICompleteLabelingRequest> => {
    try {
      const response = await customFetchWithRetry(`/ml/dataset/${datasetId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new FetchError(`Error fetching dataset details. ${await response.text()}`, response.status);
      }
  
      return response.json();
    } catch (e) {
      console.log('Error fetching dataset details', e);
      throw e instanceof FetchError ? e : new FetchError((e as Error).message, 500);
    }
  };
  
  export const saveDatasetAsDraft = async (datasetId: string, images: IImageWithLabel[]): Promise<void> => {
    try {
      const response = await customFetchWithRetry(`/ml/dataset`, {
        method: 'PUT',
        body: JSON.stringify({ images }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new FetchError(`Error saving dataset as draft. ${await response.text()}`, response.status);
      }
    } catch (e) {
      console.log('Error saving dataset as draft', e);
      throw e instanceof FetchError ? e : new FetchError((e as Error).message, 500);
    }
  };
  
  export const fetchImagesFromS3 = async (datasetId: string) => {
    try {
      const response = await customFetchWithRetry(`/ml/dataset/${datasetId}/image`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      // Ensure the response is okay
      if (!response.ok) {
        throw new FetchError(`Error fetching dataset details. ${await response.text()}`, response.status);
      }
  
      // Await and log the parsed JSON
      const responseData = await response.json();
  
      // Return the parsed response
      return responseData;
    } catch (e) {
      console.log('Error fetching dataset details', e);
      // If an error occurs, ensure it's a FetchError
      throw e instanceof FetchError ? e : new FetchError((e as Error).message, 500);
    }
  };
  

  export const updateImageLabelsApi = async (
    datasetId: UpdateImageLabelsRequest['datasetId'],
    changedLabels: UpdateImageLabelsRequest['changedLabels'],
    status: UpdateImageLabelsRequest['status'],
    grainId: UpdateImageLabelsRequest['grainId']
  ): Promise<void> => {
    const imageList = Object.entries(changedLabels).map(([imagePath, label]) => ({
      imagePath,
      label,
    }));
  
    try {
      const response = await customFetchWithRetry(`/ml/dataset`, {
        method: 'PUT',
        body: JSON.stringify({
          datasetID: datasetId,
          imageList,
          status,
          grainId,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new FetchError(`Error completing tagging. ${await response.text()}`, response.status);
      }
    } catch (e : any) {
      console.log('Error completing tagging', e);
      throw e instanceof FetchError ? e : new FetchError(e.message, 500);
    }
  };
  