import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Auth as Authorization } from 'aws-amplify';

import { Platform } from "react-native";

import * as LoginActions from '../redux/actions/loginAction';

import { AppUserForGrainAnalysisStack } from '../stack/AppUserForGrainAnalysisStack';
import { AppViewOnlyUserStack } from '../stack/AppViewOnlyUserStack';
import {MlStack} from 'src/stack/MlStack'; // Import the correct component
import { StackChooser } from '../stack/StackChooser';
import { IStore } from '../redux/reducers';
import { OrgAdminStack } from '../stack/OrgAdminStack';
import { LoginStack } from '../stack/LoginStack';
import { OrgSuperAdminStack } from 'src/stack/OrgSuperAdminStack';
import remoteConfig from '@react-native-firebase/remote-config';
import { isNative } from 'src/utils/utils';

export const App = () => {
    const userLoggedIn = useSelector((store: IStore) => store.login.userLoggedIn);
    const currentStack = useSelector((store: IStore) => store.app.currentStack);

    const [showLoginStack, setShowLoginStack] = React.useState(true);

    const dispatch = useDispatch();

    const checkAuthToken = async () => {
        Authorization.currentAuthenticatedUser().then((user) => {
            if (user !== undefined) {
                setShowLoginStack(false)
                dispatch(LoginActions.setUserLoggedIn("LOGGED_IN"))
            }
        }).catch((e) => {
            console.log('error', e)
            setShowLoginStack(true)
            dispatch(LoginActions.setUserLoggedIn("NOT_LOGGED_IN"))
        })
    }

    useEffect(() => {
        if (userLoggedIn === "UNKNOWN") {
            checkAuthToken()
        } else if (userLoggedIn === "LOGGED_IN") {
            setShowLoginStack(false)
        } else if (userLoggedIn === "NOT_LOGGED_IN") {
            setShowLoginStack(true)
        }
        setStack()
    }, [currentStack, userLoggedIn]);

    useEffect(() => {
        initRemoteConfig()
    }, []);

    const initRemoteConfig = async () => {
        if (!isNative()) {
            return
        }
        remoteConfig()
            .setDefaults({
                api_cache_expiration_duration: 86400,
                api_inference_jobs_polling_delay: 10,
                api_retry_limit_on_error: 1,
                api_inference_attemps_on_empty_results: 3,
                api_inference_page_interval: 604800,
                api_inference_refresh_interval: 86400
            })
            .then(() => remoteConfig().fetchAndActivate())
            .then(fetchedRemotely => {
                if (fetchedRemotely) {
                    console.log('Configs were retrieved from the backend and activated.');
                } else {
                    console.log(
                        'No configs were fetched from the backend, and the local configs were already activated',
                    );
                }
            });
    }

    const setStack = () => {
        if (showLoginStack) {
            return <LoginStack />
        }
        if (Platform.OS === 'web') {
            if (currentStack === 'orgAdmin') {
                return <OrgAdminStack />
            } else if (currentStack === 'orgSuperAdmin') {
                return <OrgSuperAdminStack />
            } else if (currentStack === 'appUser') {
                return <AppUserForGrainAnalysisStack />
            } else if (currentStack === 'appViewOnlyUser') {
                return <AppViewOnlyUserStack />
            }else if (currentStack === 'mlAdmin') {
                return <MlStack />
            }else {
                console.log('currentStack', currentStack)
                return <StackChooser />
            }
        } else {
            if (currentStack === 'appViewOnlyUser') {
                return <AppViewOnlyUserStack />
            } else {
                return <AppUserForGrainAnalysisStack />
            }
        }
    }

    return setStack();
}